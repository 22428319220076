//
// Pages: Login & Register
//

.login-logo,
.register-logo {
    font-size: 2.1rem;
    font-weight: 300;
    text-align: center;

    a {
        color: $gray-700;
    }
}

.login-page,
.register-page {
    align-items: center;
    background-color: $gray-200;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.login-box,
.register-box {
    width: 500px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-top: .5rem;
        width: 98%;
    }

    .card {
        margin-bottom: 0;
    }
}

.login-card-body,
.register-card-body {
    background-color: $white;
    border-top: 0;
    color: #666;
    padding: 20px;

    .input-group {
        .form-control {
            border-right: 0;

            &:focus {
                box-shadow: none;

                ~ .input-group-prepend .input-group-text,
                ~ .input-group-append .input-group-text {
                    border-color: $input-focus-border-color;
                }
            }

            &.is-valid {
                &:focus {
                    box-shadow: none;
                }

                ~ .input-group-prepend .input-group-text,
                ~ .input-group-append .input-group-text {
                    border-color: $success;
                }
            }

            &.is-invalid {
                &:focus {
                    box-shadow: none;
                }

                ~ .input-group-append .input-group-text {
                    border-color: $danger;
                }
            }
        }

        .input-group-text {
            background-color: transparent;
            border-bottom-right-radius: $border-radius;
            border-left: 0;
            border-top-right-radius: $border-radius;
            color: #777;
            transition: $input-transition;
        }
    }
}

.login-box-msg,
.register-box-msg {
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
}

.social-auth-links {
    margin: 10px 0;
}

@include dark-mode() {
    .login-card-body,
    .register-card-body {
        background-color: $dark;
        border-color: $gray-600;
        color: $white;
    }
    .login-logo,
    .register-logo {
        a {
            color: $white;
        }
    }
}
