$primaryColor: #24a4dc !default;
$primaryLightColor: #a7dbf1 !default;
$primaryDarkColor: #1d83b0 !default;
$primaryDarkerColor: #19739a !default;
$primaryTextColor: #ffffff !default;

$secondaryColor: #e43929 !default;
$secondaryLightColor: #f29c94 !default;
$secondaryDarkColor: #b62e21 !default;
$secondaryDarkerColor: #a0281d !default;
$secondaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../variables';
@import 'fonts';
@import 'assets/theme-base/components';
@import '../extensions';
